// import React, { Component } from 'react';

// //Importing Modal
// import ModalVideo from 'react-modal-video';
// import '../../../node_modules/react-modal-video/scss/modal-video.scss';

// class ModalSection extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isOpen: false
//         };
//         this.openModal.bind(this)
//     }

//     openModal() {
//         this.setState({ isOpen: true })
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <ModalVideo
//                     channel={this.props.channel}
//                     isOpen={this.state.isOpen}
//                     videoId={this.props.videoId}
//                     onClose={() => this.setState({ isOpen: false })}
//                 />
//             </React.Fragment>
//         );
//     }
// }

// export default ModalSection;

// import React, { Component } from "react";

// //Importing Modal
// import ModalVideo from "react-modal-video";
// import "../../../node_modules/react-modal-video/scss/modal-video.scss";

// class ModalSection extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isOpen: true,
//     };
//     this.openModal.bind(this);
//   }

//   openModal() {
//     this.setState({ isOpen: true });
//   }

//   render() {
//     return (
//       <React.Fragment>
//         <ModalVideo
//           channel={this.props.channel}
//           isOpen={this.state.isOpen}
//           videoId={this.props.videoId}
//           onClose={() => this.setState({ isOpen: false })}
//           autoplay={this.props.autoplay}
//           mute={this.props.mute}
//         />
//       </React.Fragment>
//     );
//   }
// }

// export default ModalSection;


import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import Cookies from "js-cookie";

class ModalSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal.bind(this);
  }

  componentDidMount() {
    // Check if the modal has been shown before
    const modalShown = Cookies.get("modalShown");
    if (!modalShown) {
      // If the modal has not been shown, open it and set the cookie
      this.setState({ isOpen: true });
      // Cookies.set("modalShown", true, { expires: 365 }); // Cookie expires after 365 days
      Cookies.set("modalShown", true);
    }
  }

  openModal() {
    this.setState({ isOpen: true });
  }


  render() {
    return (
      <React.Fragment>
        <ModalVideo
          channel={this.props.channel}
          isOpen={this.state.isOpen}
          videoId={this.props.videoId}
          onClose={() => this.setState({ isOpen: false })}
          autoplay={this.props.autoplay}
          mute={this.props.mute}
        />
      </React.Fragment>
    );
  }
}

export default ModalSection;
