import React, { useState , useEffect } from "react";
import Index2 from "./pages/Index2/Index2";
import { Route, Switch, Redirect } from "react-router-dom";
import TermsCondition from "./pages/Terms/terms";
import ShippingDeliveryPolicy from "./pages/Terms/Shipping & Delivery Policy";
import ReturnCancellationPolicy from "./pages/Terms/Return & Cancellation Policy";
import PrivacyPolicy from "./pages/Terms/Privacy Policy";
import ReactGA from "react-ga4";
import Dashboard from "./dashboard/Dashboard";
import OnlineSeminar from "./pages/OnlineSeminar/onlileseminar";
// import IsplDashboard from "./dashboard/IsplDashboard";

import RegistrationPage from "./pages/NewIslp/Registration";
import PaymentSuccess from "./pages/NewIslp/paymentSuccessPage/PaymentSuccess";
import Toasters from "./containers/toaster";
import GameDetails from "./components/Pricing/GameDetails";

// import GameDetails from "./components/Pricing/GameDetails";
// import PaymentDetails from "./pages/Payment Page/PaymentDetails";
// import BuyNowDetails from "./pages/Payment Page/BuyNowDetails";

// import NoPage from "./pages/noPage";
const TRACKING_ID = "UA-G-M75DK8Q5KY"
ReactGA.initialize(TRACKING_ID)
// ReactGA.initialize({
//   trackingId: "G-MFKDDCB2LM",
//   gaOptions: {
//     name: "prod",
//     userId: 123,
//   },
// });

function App() {
  // console.log("first", process.env.REACT_APP_ENV)
  React.useEffect(() => {
    ReactGA.send({
      hitType:"pageview",
      page: window.location.pathname + window.location.search,
      // title: "Home Page"
      // title: "My Component Page"
    });
    // localStorage.setItem("registrationNow", 1);
  }, []);
  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  // }, []);
  // const handleButtonClick = () => {
  //   ReactGA.event({
  //     category: 'User',
  //     action: 'Clicked a button'
  //   });
  // };

  return (
    <React.StrictMode>
      <Switch>
        <Route path={"/paymentsuccess"} component={PaymentSuccess} />
        <Route path={"/dashboard/registration"} component={RegistrationPage} />
        <Route path={"/terms&conditions"} component={TermsCondition} />
        <Route
          path={"/shipping&deliveryPolicy"}
          component={ShippingDeliveryPolicy}
        />
        <Route
          path={"/return&cancellationPolicy"}
          component={ReturnCancellationPolicy}
        />
        <Route path={"/privacypolicy"} component={PrivacyPolicy} />
        <Route path={"/onlineseminar"} component={OnlineSeminar} />
        <Route path={"/dashboard"} component={Dashboard} />
        <Route path={"/game-details/:id"} component={GameDetails} />
        {/* <Route path="*" element={<NoPage />} /> */}
        <Route path={"/"} component={Index2} />
        <Redirect from="/" to="/dashboard" />
      </Switch>

      <Toasters />
    </React.StrictMode>
  );
}

export default App;
