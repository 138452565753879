import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import ProductDetails from "./productDetail";
import { CircularProgress, Typography } from "@material-ui/core";
import { HOST_URL } from "../../action/api";
import Login from "./BuyNoww";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./pricing.css";

import { Autoplay } from "swiper/modules";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     position: "relative",
//     margin: 60,
//     padding: 90,
//     // justifyContent: 'center',
//     '& > * + *': {
//       marginLeft: theme.spacing(),
//     },
//   },
// }));

function PricingBox({game}) {
  // const classes = useStyles();
  const [datas, setDatas] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${HOST_URL}games/`);
        const item = await response.json();
        if (item !== null) {
          setIsLoaded(true);
          setDatas([...item, item[0], item[1]]);
        }
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, []);

  const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
    const discountedPrice =
      originalPrice - originalPrice * (discountPercentage / 100);
    return discountedPrice.toFixed(2); // Assuming 2 decimal places for the price
  };
if (!game || typeof game.game_name === 'undefined') {
    return <div>No game information available.</div>;
  }

  return (
    <React.Fragment>
      {!isLoaded ? (
        <div>
          <Row>
            <Col lg={4}>
              <div className="text-center pricing-box">
                <CircularProgress />
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center pricing-box">
                <CircularProgress />
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center pricing-box">
                <CircularProgress />
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <Swiper
          // spaceBetween={30}
          loop={true}
          centeredSlides={true}
          slidesPerView={3}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            240: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          // navigation={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {datas.map((pricing, key) => (
            <Col key={key} lg={4}>
              <SwiperSlide>
                <div className="text-left pricing-box">
                  <ProductDetails pricings={pricing} />
                  <div className="product_deteles">
                    <h3 className="text-uppercase">
                      {pricing.game_name} <sub>{pricing.game_type}</sub>
                    </h3>
                    <div className="plan-features">
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Total Game Level :
                        </span>
                        <b className="text-secondary">{"12"}</b>
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Age Group :</span>
                        <b className="text-secondary">{pricing.age_group}</b>
                      </p>
                      <p></p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Mode :</span>
                        <b className="text-secondary">{pricing.mode}</b>
                      </p>
                    </div>
                    <Login />
                  </div>
                </div>
              </SwiperSlide>
            </Col>
          ))}
        </Swiper>
      )}
    </React.Fragment>
  );
}
export default PricingBox;
