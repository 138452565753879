import * as React from "react";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core";
import { calculateDiscountedPrice } from "../../utils";

const useStyles = makeStyles({
  bullet: {
    display: "inline-block",
    margin: "0 8px",
    fontSize: "0.5rem",
    color: "#000000", // Set color to black
  },
});

function OrderSummary({ details, discount, couponCode }) {
  const classes = useStyles();
  const totalPrice = Math.floor(calculateDiscountedPrice(
    details?.level.data.price,
    details?.level.data.discount
  ))
  return (
    <>
      <Typography variant="subtitle1">
        Following game and same game level activated with this order
        <br />
        <CheckSharpIcon className={classes.bullet} /> Game Name :{" "}
        {details?.game_name}
        <br />
        <CheckSharpIcon className={classes.bullet} /> STEM KIT : Level-
        {details?.level.data.level_number} {details?.level.data.level_name}
        <br />
        <CheckSharpIcon className={classes.bullet} /> Level Name :{" "}
        {details?.level.data.level_name}
        <br />
        <CheckSharpIcon className={classes.bullet} /> Delivery :{" "}
        <span
          style={{
            color: "#6c757d",
            textDecoration: "line-through",
            marginRight: "0.5rem",
          }}
        >
          90 ₹
        </span>
        0 ₹
        <br />
        <CheckSharpIcon className={classes.bullet} />
        Total Price :{" "}
        <span
          style={{
            color: "#6c757d",
            textDecoration: "line-through",
            marginRight: "0.5rem",
          }}
        >
          {details?.level.data.price}
        </span>
        <span
          style={
            discount == "1500"
              ? {
                  color: "#6c757d",
                  textDecoration: "line-through",
                  marginRight: "0.5rem",
                }
              : { color: "#6c757d", fontWeight: "bold" }
          }
        >
          {totalPrice} INR
        </span>
        {discount == "1500" ? (
          <span style={{ color: "#6c757d", fontWeight: "bold" }}>
            {totalPrice - 1500} INR
          </span>
        ) : (
          ""
        )}
        <Typography variant="body2" sx={{ mt: 1 }}>
          <CheckSharpIcon className={classes.bullet} />{" "}
          <span style={{ color: "rgb(47 38 189)" }}>
            Save {details?.level.data.discount}% with this discount offer!
          </span>
        </Typography>
        {discount == "1500" ? (
          <>
            <Typography variant="body1">
              <CheckSharpIcon className={classes.bullet} />{" "}
              <span style={{ color: "darkorange" }}>
                {/* 🎉 Congratulations! 🎉You've saved {totalPrice / 2} rupees using
                coupon code "ILP097L". */}
                🎉 Congratulations! 🎉You've saved {discount} rupees using coupon code {couponCode}.
              </span>
            </Typography>
          </>
        ) : (
          ""
        )}
        <br />
        You will get all ilearnplace STEM gaming benefits given on the website.
        <br />
        Login to{" "}
        <a
          href="www.ilearnplace.com"
          style={{ cursor: "pointer", color: "blue" }}
        >
          {" "}
          www.ilearnplace.com{" "}
        </a>
        and start playing ILP stem learning game. Please reach out to
        <a
          href="info@ilearnplace.com"
          style={{ cursor: "pointer", color: "blue" }}
        >
          {" "}
          info@ilearnplace.com
        </a>{" "}
        or +91-8094414990 for any kind of support.
      </Typography>
    </>
  );
}

export default OrderSummary;
