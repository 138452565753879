import React from 'react';
import { Link } from 'react-router-dom';
import { HOST_URL } from '../../action/api';
export default function ProductDetails(props) {
   return (
    <React.Fragment>
      <Link className="Link" to={`game-details/${props.pricings.id}`} >
        <img src={`${HOST_URL }${props.pricings.gamephoto}/`} width='400px' alt='img' />
        <span className="btn btn-primary">Know More</span>
      </Link>
    </React.Fragment>
  );
}